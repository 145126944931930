import React, { Component } from "react"
import { useStaticQuery, StaticQuery, graphql, Link } from "gatsby"
import Img from "gatsby-image"
import Helmet from "react-helmet"
import { MDBContainer, MDBNavbar, MDBNavbarToggler, MDBNavbarNav, MDBNavItem, MDBCollapse, MDBRow, MDBCol, MDBAnimation } from "mdbreact"


import "./TLCLayout.css"

class TLCLayout extends Component {
  constructor() {
    super()
    this.state = {
      width: 0,
      collapseID: '',
      showMobileMenu: false,
    }

    if (typeof window !== `undefined`) {
      this.state = {
        width: window.innerWidth,
        collapseID: '',
        showMobileMenu: false,
      }
    }
  }

  toggleCollapse = collapseID => () => {
    this.setState(prevState => ({
      collapseID: prevState.collapseID !== collapseID ? collapseID : ''
    }));
  };

  componentWillMount() {
    if (typeof window !== `undefined`) {
      window.addEventListener('resize', this.handleWindowSizeChange)
      if(this.state.width <= 1126)
        this.setState({showMobileMenu: true})
    }
  }

  handleWindowSizeChange = () => {
    this.setState({ width: window.innerWidth })

    if(this.state.width <= 1126)
      this.setState({showMobileMenu: true})
    else
      this.setState({showMobileMenu: false})
  }

  render() {
    /* Header Styles */
    var headerContainerStyle = {
      backgroundColor: 'rgba(0,0,0,.50)',
    }

    var headerStyle = {
      height: '40px',  
      color: '#fff',
      fontFamily: `"Roboto", sans-serif"`,
      fontSize: '13px',
      fontWeight: 400,
    }

    var menuStyle = {
      backgroundColor: 'rgba(0,0,0,.30)',
      height: '75px',
    }

    var mobileMenuStyle = {
      backgroundColor: 'rgba(0,0,0,.30)',
      height: '75px',
    }  
    
    var mobileLink = {
      color: 'rgb(255,255,255)',
      fontWeight: 400,
      fontSize: '1.5em',
    }

    var socialLinkStyle = {
      color: '#fff',
      paddingLeft: '0.5em',
      paddingRight: '0.5em',
    }    


    /* Hero Styles */
    var siteContainerStyle = {
        // background: `url(${this.props.heroImageUrl}) no-repeat top center`,
        // backgroundSize: '100%',
        padding: 0,
    }

    var heroContainerStyleHome = {
      marginTop: '20em',
      marginBottom: '20em',
    }

    var heroContainerStyleSubpage = {
      marginTop: '1em',
      marginBottom: '1em',
    }

    var heroTitleStyleHome = {
        color: 'rgb(255, 255, 255)',
        minHeight: '0px',
        minWidth: '0px',
        lineHeight: '52px',
        borderWidth: '0px',
        margin: '30px 0px 20px',
        padding: '0px',
        letterSpacing: '9px',
        fontSize: '48px',
        fontFamily: `"Dosis", sans-serif`,
        textTransform: 'uppercase',
        fontWeight: '300',     
    }

    var heroTitleStyleSubpage = {
      color: 'rgb(255, 255, 255)',
      minHeight: '0px',
      minWidth: '0px',
      lineHeight: '30px',
      borderWidth: '0px',
      margin: '30px 0px 20px',
      padding: '0px',
      letterSpacing: '9px',
      fontSize: '30px',
      fontFamily: `"Dosis", sans-serif`,
      textTransform: 'uppercase',
      fontWeight: '300',     
  }

    var heroSubTitleStyle = {
        color: 'rgb(254, 212, 36)',
        minHeight: '0px',
        minWidth: '0px',
        lineHeight: '33px',
        borderWidth: '0px',
        margin: '30px 0px 20px',
        padding: '0px',
        letterSpacing: '6px',
        fontSize: '30px',
        fontFamily: `"Dosis", sans-serif`,
        textTransform: 'uppercase',
        fontWeight: '300',     
    }

    /* Site Content Styles */
    var siteContentContainerStyle = {
      backgroundColor: '#fff',
      padding: 0,
    }


    /* Footer Styles */
    var footerContainerStyle = {
      backgroundColor: '#444850',
      color: '#ccc',
      paddingTop: '50px',
      paddingBottom: '10px',
      paddingLeft: 0,
      paddingRight: 0,
    }

    var socialLinkStyle = {
        paddingLeft: '0.5em',
        paddingRight: '0.5em', 
        color: '#ccc'           
    }   
    
    var siteContentStyle = {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
    }

    return(
      <>
        <Helmet>
          <link href="https://fonts.googleapis.com/css?family=Dosis:200,300,400|Roboto:200,300,400|Roboto+Condensed:200,300,400&display=swap" rel="stylesheet" />          
        </Helmet>
        
        <MDBContainer fluid style={siteContainerStyle}>
          {/* <img src={this.props.heroImageUrl} /> */}
          {/* <Img fluid={this.props.heroImage} /> */}
          
          {/* TODO: Will likely need to rfactor this to pull in more data in the future.  Should be able to wrap the whole template within the render property */}
          <StaticQuery
            query={graphql`
              query {
                heroImage: file(relativePath: { eq: "hero-dark-2.jpg" }) {
                  childImageSharp {
                    fluid(maxWidth: 3000) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
            `}
            render={data => (
                <Img fluid={data.heroImage.childImageSharp.fluid} />
            )}
          />         
          <div style={siteContentStyle}>

            {/* Main Menu Start */}
            <MDBContainer fluid style={headerContainerStyle}>
              <MDBContainer>
                <MDBRow className="align-items-center" style={headerStyle}>
                  <MDBCol md="5">
                    <i className="far fa-clock" />&nbsp;
                    We're Open: Monday - Friday, 8:00 am - 5:00 pm
                  </MDBCol>
                  <MDBCol md="5"></MDBCol>
                  <MDBCol md="2">
                    <MDBRow className="justify-content-end">
                      <a href="https://twitter.com/tlcautodetail" style={socialLinkStyle}>
                        <i className="fab fa-twitter"></i>
                      </a>
                      <a href="https://facebook.com/tlcautodetail" style={socialLinkStyle}>
                        <i className="fab fa-facebook"></i>
                      </a>
                      <a href="https://instagram.com/tlcautodetail" style={socialLinkStyle}>
                        <i className="fab fa-instagram"></i>
                      </a>                  
                    </MDBRow>
                  </MDBCol>
                </MDBRow>
              </MDBContainer>
            </MDBContainer>



  

            {/* Normal Site Nav Start */}
            <MDBNavbar className={this.state.showMobileMenu ? 'd-none' : 'd-block'} color="bg-dark" sticky="top" dark scrolling transparent style={menuStyle}>
              <MDBContainer>
                <MDBCol md="2">
                  <MDBRow className="justify-content-start">
                    <img src="images/tlc-logo.png" width="250" />
                  </MDBRow>
                </MDBCol>
                <MDBCol md="10" className="align-self-end">
                  <MDBRow className="justify-content-end">
                    <div className="nav-wrap">
                      <div className="btn-menu"></div>
                      <nav id="mainnav" className="mainnav">
                        <ul className="menu">
                          { !this.props.homepage && 
                            <li><Link to="/">Home</Link></li>
                          }
                          <li>
                            <Link to="/about">About</Link>
                            <ul className="submenu"> 
                              <li><Link to="/about#ourteam">Our Team</Link></li>
                              <li><Link to="/about#faq">Questions &amp; Answers</Link></li>
                              <li><Link to="/about#reviews">Reviews</Link></li>
                            </ul>
                          </li>
                          <li>
                            <Link>Services</Link>
                            <ul className="submenu">
                              <li className="sub-parent">
                                <Link to="/detailing">Detailing</Link>
                                {/* <Link to="/services#wash">Detailing</Link> */}
                                {/* <ul className="submenu">
                                  <li><Link to="/services#middle">Maintenance Wash</Link></li>
                                  <li><a href="#">Maintenance Wash</a></li>
                                  <li><a href="#">Signature Protection Detail</a></li>
                                  <li><a href="#">Interior Detail</a></li>
                                  <li><a href="#">Paint Correction</a></li>
                                </ul> */}
                              </li>
                              <li className="sub-parent">
                                <Link to="/paintrefinement">Paint Refinement</Link>
                              </li>                              
                              <li className="sub-parent">
                                <Link to="/ceramiccoatings">Ceramic Coatings</Link>
                              </li>
                              <li className="sub-parent">
                                <Link to="/paintprotectionfilm">Paint Protection Film</Link>
                              </li>
                              <li className="sub-parent">
                                <Link to="/windshieldprotectionfilm">ExoShield Windshield Protection Film</Link>
                              </li>
                              <li className="sub-parent">
                                <Link to="/windowtint">Window Tint</Link>
                              </li>
                            </ul>
                          </li>
                          {/* <li><Link to="/blog">Blog</Link></li> */}
                          <li><Link to="/gallery">Gallery</Link></li>                         
                          {/* <li><Link to="/shop">Shop</Link></li> */}
                          <li><Link to="/contact">Contact</Link></li>
                        </ul>                                         
                      </nav>
                    </div>    
                  </MDBRow>
                </MDBCol>
              </MDBContainer>
            </MDBNavbar>                 
            {/* Normal Site Nav End */}

            {/* Mobile Site Nav Start */}
            <MDBNavbar className={this.state.showMobileMenu ? 'd-block' : 'd-none'} color="bg-dark" sticky="top" dark scrolling transparent style={mobileMenuStyle}>
              <MDBContainer>
                  <MDBCol size="5">
                    <img src="images/tlc-logo.png" width="140" />
                  </MDBCol>         
                  <MDBCol size="7" className="d-flex justify-content-end">
                    <MDBNavbarToggler style={{ backgroundColor: '#2d5aae'}} onClick={this.toggleCollapse('navbarCollapse1')} />
                  </MDBCol>
              </MDBContainer>
              {/* 
              <MDBCollapse
                id='navbarCollapse1'
                isOpen={this.state.collapseID}
                navbar
                style={{backgroundColor: '#2d5aae', padding: '10px', marginTop: '10px'}}
              >
                <MDBNavbarNav left>
                  <MDBNavItem>
                    <Link style={mobileLink} to="/">Home</Link>
                  </MDBNavItem>
                  <MDBNavItem>
                    <Link style={mobileLink} to="/about">About</Link>
                  </MDBNavItem>
                  <MDBNavItem>
                    <Link style={mobileLink} to="/services">Services</Link>
                  </MDBNavItem>
                  <MDBNavItem>
                    <Link style={mobileLink} to="/gallery">Gallery</Link>
                  </MDBNavItem> 
                  <MDBNavItem>
                    <Link style={mobileLink} to="/contact">Contact</Link>
                  </MDBNavItem>                                   
                </MDBNavbarNav>
              </MDBCollapse>                
            </MDBNavbar>    
            */}   
              <MDBCollapse
                id='navbarCollapse1'
                isOpen={this.state.collapseID}
                navbar
                style={{backgroundColor: '#2d5aae', padding: '10px', marginTop: '10px'}}
              >
                <MDBNavbarNav left>
                  <MDBNavItem>
                    <Link style={mobileLink} to="/">Home</Link>
                  </MDBNavItem>
                  <MDBNavItem>
                    <Link style={mobileLink} to="/about">About</Link>
                  </MDBNavItem>
                  <MDBNavItem>
                    <Link style={mobileLink} to="/detailing">Detailing</Link>
                  </MDBNavItem>
                  <MDBNavItem>
                    <Link style={mobileLink} to="/paintrefinement">Paint Refinement</Link>
                  </MDBNavItem> 
                  <MDBNavItem>
                    <Link style={mobileLink} to="/ceramiccoatings">Ceramic Coatings</Link>
                  </MDBNavItem>  
                  <MDBNavItem>
                    <Link style={mobileLink} to="/paintprotectionfilm">Paint Protection Film</Link>
                  </MDBNavItem>  
                  <MDBNavItem>
                    <Link style={mobileLink} to="/windshieldprotectionfilm">ExoShield Windshield Protection Film</Link>
                  </MDBNavItem> 
                  <MDBNavItem>
                    <Link style={mobileLink} to="/windowtint">Window Tint</Link>
                  </MDBNavItem>                                   
                  <MDBNavItem>
                    <Link style={mobileLink} to="/gallery">Gallery</Link>
                  </MDBNavItem> 
                  <MDBNavItem>
                    <Link style={mobileLink} to="/contact">Contact</Link>
                  </MDBNavItem>                                   
                </MDBNavbarNav>
              </MDBCollapse>                
            </MDBNavbar>                                 
            {/* Mobile Site Nav End */}   
            {/* Main Menu End */}

            {/* Hero Begin */}
            <div className="flex-center" style={(this.props.homepage) ? heroContainerStyleHome : heroContainerStyleSubpage}>
                <div className={(this.props.homepage) ? "container text-center white-text wow fadeInUp" : "container text-left white-text wow fadeInUp"}>
                    { this.props.homepage &&
                      <MDBAnimation type="fadeInLeft" duration="1.5s">
                          <h2 style={heroSubTitleStyle}>{this.props.heroSubtitle}</h2>
                      </MDBAnimation>
                    }
                    <MDBAnimation type="fadeInRight" duration="1.5s" delay={this.props.homepage ? "1s" : "0s"}>
                        <h1 style={(this.props.homepage) ? heroTitleStyleHome : heroTitleStyleSubpage}>{this.props.heroTitle}</h1>
                    </MDBAnimation>
                </div>
            </div>
            {/* Hero End */}

            {/* Body Begin */}
            <MDBContainer fluid style={siteContentContainerStyle}>
              { this.props.children }
            </MDBContainer>
            {/* Body End */}

            {/* Footer Begin */}
            <MDBContainer fluid style={footerContainerStyle}>
                  <MDBContainer style={{paddingBottom: '25px'}}>
                      <MDBRow>
                          <MDBCol>
                              <img src="images/tlc-logo.png" width="200px" />
                              <p>We offer a commitment to personalized service for 
                              our clients. If you have further questions or need help with a case,
                              please complete our quick form below. A team member will return 
                              your message as soon as possible.</p>
                              <MDBRow>
                                  <MDBCol>
                                      <i className="fa fa-map-marker" style={{marginRight: '10px'}}></i>
                                      <strong>106 Oakgrove Rd. Suite L<br />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Dulles, VA 20166</strong> <br />
                                      <i className="fas fa-phone" style={{marginRight: '8px'}}></i>
                                      <strong>Tel:</strong> 703.962.7106
                                  </MDBCol>
                                  <MDBCol>
                                      <i className="far fa-envelope" style={{marginRight: '10px'}}></i>
                                      <strong>E-mail:</strong><br />
                                      <a href="mailto:info@tlcautodetail.com">info@tlcautodetail.com</a>
                                  </MDBCol>
                              </MDBRow>
                          </MDBCol>
                          <MDBCol>
                              <h5>Quick Links</h5>
                              <ul className="footer-quicklinkList">
                                  <li className="footer-quicklink"><i className="fas fa-angle-right"></i>&nbsp;<Link to="/">Home</Link></li>
                                  <li className="footer-quicklink"><i className="fas fa-angle-right"></i>&nbsp;<Link to="/about">About</Link></li>
                                  <li className="footer-quicklink"><i className="fas fa-angle-right"></i>&nbsp;<Link to="/services">Services</Link></li>
                                  <li className="footer-quicklink"><i className="fas fa-angle-right"></i>&nbsp;<Link to="/gallery">Gallery</Link></li>                                
                                  {/* <li className="footer-quicklink"><i className="fas fa-angle-right"></i>Blog</li>
                                  <li className="footer-quicklink"><i className="fas fa-angle-right"></i>Shop</li> */}
                                  <li className="footer-quicklink"><i className="fas fa-angle-right"></i>&nbsp;<Link to="/contact">Contact</Link></li>
                              </ul>
                          </MDBCol>
                      </MDBRow>
                    </MDBContainer>
                    <MDBContainer fluid style={{padding: 0}}>
                      <MDBRow>
                        <MDBCol>
                          <hr style={{border: '0.5px solid rgb(150,150,150)'}} width="100%" />
                        </MDBCol>
                      </MDBRow>
                    </MDBContainer>
                    <MDBContainer>
                      <MDBRow style={{paddingTop: '25px', paddingBottom: '25px'}}>
                          <MDBCol md="10">Copyright © {new Date().getFullYear()} TLC Auto Detail </MDBCol>
                          <MDBCol md="2">
                              <a href="https://twitter.com/tlcautodetail" style={socialLinkStyle}>
                                  <i className="fab fa-twitter"></i>
                              </a>
                              <a href="https://facebook.com/tlcautodetail" style={socialLinkStyle}>
                                  <i className="fab fa-facebook"></i>
                              </a>
                              <a href="https://instagram.com/tlcautodetail" style={socialLinkStyle}>
                                  <i className="fab fa-instagram"></i>
                              </a>                             
                          </MDBCol>                        
                      </MDBRow>
                    </MDBContainer>
              </MDBContainer>
              {/* Footer End */}
            </div>
        </MDBContainer> 
      </>
    )
  }
}

export default TLCLayout

